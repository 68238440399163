import React from "react"
import { withPrefix } from "gatsby"

import Form from "./components/Form"

const defaultData = {
  image: withPrefix("img/vent4.jpg"),
}

export default function index({
  image,
  title,
  availablePackages,
  selectedPackage,
  paymentLink,
  ctaHeading,
  ctaText,
}) {
  return (
    <section className="bg-darker parallax image-bg overlay " id="form">
      <div className="background-image-holder">
        <img
          alt={title || "Happy students"}
          className="background-image"
          src={image ? image : defaultData.image}
        />
      </div>
      <div className="col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1">
        <Form
          service={selectedPackage}
          services={availablePackages}
          paymentLink={paymentLink}
          ctaHeading={ctaHeading}
          ctaText={ctaText}
        />
      </div>
    </section>
  )
}
