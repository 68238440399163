import React from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import useFormFields from "../../../../../libs/useFormFields"
import { submitData } from "../../../../../libs/helperFunctions"
import SelectPayment from "./SelectPayment"

const defaultValues = {
  heading: "Schedule your Shoot",
  text: "I am delighted to work with you on this project. However, I need to have information to plan out the best version of this shoot.",
  pay: process.env.REACT_APP_PAY_LINK,
}

export default function Form({
  services,
  service,
  paymentLink,
  ctaHeading,
  ctaText,
}) {
  const [selectedPackage, setSelectedPackage] = React.useState(service)

  const [fields, handleFieldChange, setField] = useFormFields({
    date: "",
    firstName: "",
    lastName: "",
    email: "",
    persons: 0,
    phone: "",
    message: "",
    package: selectedPackage?.title || "",
    preferredDate: "",
    referer: "",
  })

  const [loading, setLoading] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [payNow, setPayNow] = React.useState(false)

  const calculatePrice = (title, data) => {
    if (data.length) {
      let f = data?.filter(p => p.title === title)
      return f[0]
    }
    return null
  }

  React.useEffect(() => {
    setSelectedPackage(service)
    if (service?.title) {
      setField("package", service.title)
    }
  }, [service])

  React.useEffect(() => {
    const set = calculatePrice(fields.package, services)
    set ? setSelectedPackage(set) : setSelectedPackage(service)
  }, [fields.package, service, services])

  React.useEffect(() => {
    if (payNow) {
      toast.success("You will be redirected to our payment page", {
        position: "bottom-center",
      })
      setTimeout(() => {
        window.open(paymentLink || defaultValues.pay, "_blank")
        setPayNow(false)
      }, 3000)
    }
  }, [payNow, paymentLink])

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const submitForm = async e => {
    e.preventDefault()
    setLoading(true)
    console.log("fields", fields)
    try {
      let data = await submitData(fields, "/.netlify/functions/book")
      data = data.json()

      setSuccess("The Ajax was a mistake. We are fixing it")
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "senior", ...fields }),
      })
      setLoading(false)
    } catch (e) {
      console.error("error", e)
      setError("An error occured please try again")
      setLoading(false)
    }
  }

  const selectPaymentTime = e => {
    setPayNow(e.target.value)
  }

  return (
    <div className="feature boxed bg-secondary">
      <ToastContainer />
      <form
        className="text-center"
        data-netlify="true"
        name="senior"
        onSubmit={submitForm}
      >
        <h4 className="uppercase mt48 mt-xs-0">
          {ctaHeading || defaultValues.heading}
        </h4>
        <p className="mb64 mb-xs-24 bold">{ctaText || defaultValues.text}</p>
        <SelectPayment
          selectPayment={payNow}
          setPaymentTime={selectPaymentTime}
        />

        {!payNow && (
          <>
            <div className="overflow-hidden">
              <h6 className="uppercase">Your personal details</h6>

              <input
                type="text"
                className="col-md-6 validate-required"
                placeholder="First Name*"
                value={fields.firstName}
                onChange={handleFieldChange}
                id="firstName"
                required
              />
              <input
                type="text"
                className="col-md-6 validate-required"
                placeholder="Last Name*"
                value={fields.lastName}
                onChange={handleFieldChange}
                id="lastName"
                required
              />
              <input
                type="text"
                name="formName"
                className="col-md-6 hidden"
                placeholder="form Name"
                value={fields.formName}
                onChange={handleFieldChange}
                id="formName"
              />
              <input
                type="text"
                name="email"
                className="col-md-6 validate-required validate-email"
                placeholder="Your Email Address*"
                value={fields.email}
                onChange={handleFieldChange}
                id="email"
                required
              />
              <input
                className="col-md-6"
                type="text"
                name="phone"
                placeholder="Your Phone Number"
                value={fields.phone}
                onChange={handleFieldChange}
                id="phone"
              />
              <textarea
                name="message"
                placeholder="Additional comments"
                rows="2"
                value={fields.message}
                onChange={handleFieldChange}
                id="message"
              ></textarea>
              <hr />
            </div>
            <div className="overflow-hidden">
              <div className="">
                <h6 className="uppercase">Package Details</h6>
                <div className="select-option">
                  <i className="ti-angle-down"></i>
                  <select
                    name="shoot"
                    onSelectCapture={handleFieldChange}
                    onChange={handleFieldChange}
                    id="package"
                    value={fields?.package}
                  >
                    <option value="Default">{"Preferred Package"}</option>
                    {services &&
                      services.length &&
                      services.map(s => (
                        <option key={s.title} value={s.title}>
                          {s.title}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="input-with-label text-left form-group ">
                  <span>Preferred Date:</span>
                  <input
                    className="form-control"
                    id="date"
                    type="date"
                    placeholder="Preferred Date"
                    value={fields.date}
                    onChange={handleFieldChange}
                  />
                </div>
                <textarea
                  name="referer"
                  placeholder="How did you get to know about us "
                  rows="1"
                  value={fields.referer}
                  onChange={handleFieldChange}
                  id="referer"
                ></textarea>

                <button disabled={loading} type="submit">
                  Submit
                </button>

                {success && (
                  <div className="form-success">
                    Thanks for your interest, we will send you an email shortly.
                    Don't forget to check your spam.
                  </div>
                )}
                {error && <div className="form-error">{error}</div>}
              </div>
            </div>
          </>
        )}
      </form>
    </div>
  )
}
